<template>
  <vs-form v-if="!isFetching" ref="form">
    <vs-wrapper>
      <PrioritySelector
        v-if="!isDisabled"
        :selected="priorityId"
        @on-update="updatePriority"
      />

      <vs-heading type="overline">Who's it for</vs-heading>
      <vs-wrapper space="small">
        <div class="grid">
          <div class="grid-item">
            <vs-autocomplete
              data-test="division-selector"
              :value="ownerId"
              :items="divisionArray"
              item-text="name"
              item-value="uuid"
              label="Division"
              required
              :disabled="isDisabled"
              @input="$emit('update:ownerId', $event)"
            />
          </div>

          <div class="grid-item">
            <JobRequesterSelector
              :customer-sid="customerSid"
              :is-disabled="isDisabled || !canModifyRequester"
              :job-hub-id="hubId"
              @selected="$emit('update:customerSid', $event)"
              @autofill="(field, value) => autoFill(field, value)"
              @show-new-guest-user-bottom-sheet="showNewGuestUserBottomSheet"
            />
          </div>
        </div>

        <vs-autocomplete
          data-test="followers-selector"
          :value="followers"
          :items="userArray"
          item-text="name"
          item-value="id"
          label="Followers"
          multiselect
          :disabled="isDisabled"
          @input="$emit('update:followers', $event)"
        />
      </vs-wrapper>
    </vs-wrapper>

    <task-item
      ref="task-item"
      :key="jobId"
      :unit-id="unitId"
      :service-provider-business-unit-id="serviceProviderBusinessUnitId"
      :job-status="jobStatus"
      :status="unitWorkingStatus"
      :active-duration="activeDuration"
      :activity-id="activityId"
      :resource-type-id="resourceTypeId"
      :inventory="inventory"
      :crew="crew"
      :is-disabled="isDisabled"
      :hub-id="hubId"
      :account-id="accountId"
      :destination-location-id="destinationLocationId"
      :assignment-group-number="assignmentGroupNumber"
      :pool-id="poolId"
      :pool-definition-id="poolDefinitionId"
      :is-creating-ad-hoc-pool="isCreatingAdHocPool"
      :map-link="mapLink"
      @remove-job-from-group="removeJobFromGroup"
      @update-unit="updateUnit"
      @update-service-provider-business-unit-id="
        updateServiceProviderBusinessUnitId
      "
      @update-activity="updateActivity"
      @update-crew="updateCrew"
      @update-inventory="updateInventory"
      @update-destination-location-id="updateDestination"
      @auto-fill="autoFill"
      @update-pool="assignToPool"
      @create-ad-hoc-pool="createAdHocPool"
      @add-location:open="$emit('add-location:open')"
      @add-location:close="$emit('add-location:close')"
    >
      <template #assigned>
        <pool-status
          v-if="jobStatus === 'AssignedToPool' && poolId"
          :pool-id="poolId"
          @status:closed="onPoolClosed"
        />
        <ad-hoc-pool
          v-else-if="poolDefinitionId"
          :definition-id="poolDefinitionId"
          :hub-id="hubId"
          :resource-type-id="activity.resourceTypeId"
          :itineraries="itineraries"
          @input="updatePoolExecutionPlan"
        />
        <ad-hoc-pool-creator
          v-else-if="isCreatingAdHocPool"
          :hub-id="hubId"
          :resource-type-id="activity.resourceTypeId"
          :ad-hoc-pool="adHocPool"
          @add-stage="addAdHocPoolStage"
          @update-stage="updateAdHocPoolStage"
          @remove-stage="removeAdHocPoolStage"
          @update-delay="updateAdHocPoolDelay"
        />
      </template>
    </task-item>

    <v-row no-gutters align="center">
      <v-col>
        <vs-heading type="overline">When?</vs-heading>
      </v-col>
      <v-col v-if="showBulkCreateIcon" class="shrink">
        <div>
          <v-tooltip top nudge-left="60">
            <template #activator="{ on }">
              <div v-on="on">
                <vs-button
                  :disabled="cannotCloseBulkCreate || cannotOpenBulkCreate"
                  data-test="bulk-create"
                  :icon="bulkCreate ? 'today' : 'date_range'"
                  type="tertiary"
                  small
                  @click="toggleBulkCreate"
                />
              </div>
            </template>
            <span v-if="bulkCreate && !cannotCloseBulkCreate"
              >Create single job</span
            >
            <span v-else-if="cannotCloseBulkCreate"
              >Clear job selection to create single job</span
            >
            <span v-else-if="cannotOpenBulkCreate"
              >Clear attachments to create multiple jobs</span
            >
            <span v-else>Create multiple jobs</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>

    <vs-wrapper space="small">
      <ticket-form-multi-day-scheduler
        v-if="bulkCreate"
        :start-time="startTime"
        :end-time="endTime"
        :time-slot-dates="timeSlotDates"
        :time-slot-start="timeSlotStart"
        :time-slot-duration="timeSlotDuration"
        @update-time-slot-dates="updateTimeSlotDates"
        @update-time-slot-start="updateTimeSlotStart"
        @update-time-slot-duration="updateTimeSlotDuration"
      />
      <v-row v-else no-gutters>
        <v-col>
          <vs-date-picker
            :key="`${jobId}-start-time`"
            data-test="job-start-date"
            :value="startTime"
            label="Start"
            :disabled="isDisabled"
            :can-select-past="canSelectPast"
            :rules="[() => showTimeWarning && 'Start cannot be after end']"
            required
            @input="updateStartTime"
          />
        </v-col>
        <v-col class="pl-2">
          <vs-date-picker
            :key="`${jobId}-end-time`"
            data-test="job-end-date"
            :value="endTime"
            label="End"
            :can-select-past="canSelectPast"
            :disabled="isDisabled"
            required
            @input="updateEndTime"
          />
        </v-col>
      </v-row>
    </vs-wrapper>

    <vs-heading type="overline">Details</vs-heading>
    <vs-wrapper space="small">
      <UpdateBanner
        v-if="shouldShowUpdateCustomFieldsBanner"
        @update-schema-requested="updateSchemaButtonClicked"
      />

      <CostCenterField
        data-test="costcenter-selector"
        :value="costCenter"
        :ticket-owner="ownerId"
        :ticket-destination="destinationLocationId"
        :ticket-sources="pickupLocationIds"
        :hub-id="hubId"
        label="Cost center"
        :disabled="isDisabled"
        @input="updateCostCenter"
        @auto-fill="autoFill"
      />

      <vs-text-input
        :value="details"
        data-test="job-additional-details"
        label="Additional details..."
        :disabled="isDisabled"
        multiline
        @input="updateDetails"
      />

      <ticket-tags
        data-test="job-tags-picker"
        :value="tags"
        :disabled="isDisabled"
        :hub-id="hubId"
        @input="updateTags"
      />
    </vs-wrapper>

    <vs-wrapper>
      <custom-fields-fetch-progress-indicator
        v-if="
          (loadingExtensionSchema || errorFetchingExtensionSchema) &&
          customFieldVersioningEnabled
        "
        :error-fetching-extension-schema="errorFetchingExtensionSchema"
        @retry="retryFetchingExtensionSchema"
      />
      <schema-field
        v-if="!errorFetchingExtensionSchema && !!schema.properties"
        :error-schema="errorSchema"
        :form-data="customData"
        :business-unit-id="hubId"
        :params="{ headingType: 'heading' }"
        :root-schema="schema"
        :schema="schema"
        :ui-schema="uiSchema"
        :form-resolver="contextualizedFormResolver"
        :disabled="isDisabled"
        @change="updateCustomData"
      />
    </vs-wrapper>

    <vs-wrapper>
      <template v-for="form in forms">
        <job-inline-form
          v-if="form.isFilledOut"
          :key="form.formSubmissionUuid"
          class="pt-2"
          :form-submission-id="form.formSubmissionUuid"
          @edit-form-submission="
            $emit('edit-form-submission', form.formId, form.formSubmissionUuid)
          "
        />
        <v-row v-else :key="form.formId">
          <v-col class="shrink">
            <v-icon small>mdi-clipboard-text-outline</v-icon>
          </v-col>
          <v-col class="grow">
            <vs-text>{{ form.name }}</vs-text>
          </v-col>

          <v-col class="shrink">
            <vs-button
              data-test="fill-job-form"
              label="Fill out"
              type="secondary"
              small
              @click="fillFormSubmission(form.formId)"
            />
          </v-col>
        </v-row>
      </template>
    </vs-wrapper>

    <vs-wrapper>
      <assignment-form-requirements
        :form-requirements="assignmentFormRequirements"
        :service-provider-business-unit-id="serviceProviderBusinessUnitId"
        :hub-id="hubId"
        :is-new-job="isNew"
        @fill-form-requirement="fillFormRequirement"
        @view-form-requirement-submission="viewFormRequirementSubmission"
        @update-form-requirements="updateAssignmentFormRequirements"
        @show-assignment-form-selection="showAssignmentFormSelection"
      />
    </vs-wrapper>

    <Attachments
      v-if="areAttachmentsEnabled"
      :job-uuid="jobUuid"
      :client-only="jobId === null"
      :job-attachments="attachments"
      :attachment-storage-id="attachmentsStorageId"
      :enable-delete="canEditAttachments"
      @attachment-removed="handleAttachmentRemoved"
    >
      <template>
        <attachment-card
          v-for="formSubmission in formSubmissions"
          :key="`formSubmission-${formSubmission.formSubmissionId}`"
          icon="assignment_turned_in"
          :primary-text="formSubmission.formName"
          :secondary-text="getAdhocFormSubmissionSecondaryText(formSubmission)"
        >
          <template #primary-text>
            <a
              class="text-decoration-underline"
              @click.prevent="
                $emit(
                  'show-form-submission',
                  formSubmission.formId,
                  formSubmission.formSubmissionId
                )
              "
              >{{ formSubmission.formName }}</a
            >
          </template>
        </attachment-card>
      </template>
    </Attachments>

    <vs-wrapper v-if="addFormsEnabled || canEditAttachments" space="small">
      <v-row dense>
        <v-col class="shrink" align-self="center">
          <vs-text class="add-text">Add:</vs-text>
        </v-col>
        <v-col class="shrink" align-self="center">
          <attachment-picker
            v-if="canEditAttachments"
            class="pl-0"
            data-test="add-attachments"
            :job-uuid="jobUuid"
            :job-is-new="jobId === null"
            @attachment-added="handleAttachmentAdded"
            @attachment-updated="handleAttachmentUpdated"
            @unsupported-file-selected="unsupportedFileSelected"
            @error-uploading-attachment="errorUploadingAttachment"
          />
        </v-col>
        <v-divider
          v-if="canEditAttachments && addFormsEnabled"
          class="my-3"
          vertical
        />
        <v-col class="shrink" align-self="center">
          <vs-button
            v-if="addFormsEnabled"
            icon="mdi-clipboard-text-outline"
            label="Form"
            type="tertiary"
            small
            @click="showFormSelection"
          />
        </v-col>
      </v-row>
    </vs-wrapper>

    <line-items-card
      v-if="canViewLineItems"
      :job-id="jobUuid"
      :job-approval-status="approvalStatus"
      show-batch-link
      :readonly="canEditLineItems"
      :batch-id="batchId"
      :assigned-to-service-provider-id="serviceProviderBusinessUnitId"
      :hub-id="hubId"
      :line-items-when-signed="signature?.metadata.itemsWhenSigned"
      @show-line-item-bottom-sheet="showLineItemBottomSheet"
    />

    <job-signature
      :job-id="jobUuid"
      :job-status="jobStatus"
      :job-approval-status="approvalStatus"
      :signature="signature"
      @clear-signature="clearSignature"
      @open-signature-bottom-sheet="showSignatureBottomSheet"
    />

    <job-activity-log
      v-if="!isNew"
      :job-id="jobUuid"
      :job-hub-id="hubId"
      :comments="comments"
      :history="history"
      :start-time="startTime"
      :end-time="endTime"
      :load-history-error="loadHistoryError"
      @submit-comment="createComment"
    />
  </vs-form>
</template>

<script>
import moment from 'moment'
import { isEmpty } from 'lodash'
import { computed, defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

import { formatDateTime } from '@/utils/date-formatting'
import { FormContext } from '@/utils/forms/form-context'
import { ContextualizedFormResolver } from '@/utils/forms/contextualized-form-resolver'
import {
  mergeFormDataWithDefault,
  resolveFormDataForValidation,
} from '@/utils/forms/form-resolver'
import { validateFormData } from '@/utils/forms/form-validate'
import { mergeJsonSchemas } from '@/utils/json-schema-merger'

import CostCenterField from '@/components/common/CostCenterField'
import { useJobsSearch } from '@/components/jobs/search'
import LineItemsCard from '@/components/jobs/line-items/LineItemsCard.vue'
import JobSignature from '@/components/jobs/sections/JobSignature.vue'
import VsWrapper from '@/components/vision/VsWrapper.vue'
import VsButton from '@/components/vision/VsButton.vue'
import VsAlert from '@/components/vision/VsAlert.vue'

import AdHocPool from './AdHocPool.vue'
import AdHocPoolCreator from './AdHocPoolCreator.vue'
import AssignmentFormRequirements from './AssignmentFormRequirements.vue'
import AttachmentCard from './AttachmentCard'
import AttachmentPicker from './AttachmentPicker'
import Attachments from './Attachments'
import CustomFieldsFetchProgressIndicator from './CustomFields/CustomFieldsFetchProgressIndicator.vue'
import JobInlineForm from './JobInlineForm'
import JobRequesterSelector from './JobRequesterSelector.vue'
import PoolStatus from './PoolStatus.vue'
import PrioritySelector from './PrioritySelector'
import TaskItem from './TaskItem.vue'
import TicketFormMultiDayScheduler from './TicketFormMultiDayScheduler'
import TicketTags from './TicketTags.vue'
import UpdateBanner from './CustomFields/UpdateBanner.vue'
import JobActivityLog from '@/components/tickets/history/JobActivityLog.vue'

export default {
  name: 'TicketForm',
  components: {
    JobRequesterSelector,
    JobSignature,
    VsAlert,
    VsButton,
    VsWrapper,
    LineItemsCard,
    AssignmentFormRequirements,
    CostCenterField,
    PrioritySelector,
    Attachments,
    AttachmentPicker,
    TicketTags,
    TicketFormMultiDayScheduler,
    TaskItem,
    JobInlineForm,
    AttachmentCard,
    JobActivityLog,
    PoolStatus,
    AdHocPool,
    AdHocPoolCreator,
    CustomFieldsFetchProgressIndicator,
    UpdateBanner,
  },
  props: {
    jobId: {
      type: Number,
      default: null,
    },
    jobUuid: {
      type: String,
      default: null,
    },
    unitWorkingStatus: {
      type: String,
      default: () => '',
    },
    activeDuration: {
      type: Object,
      default: null,
    },
    priorityId: {
      type: Number,
      default: null,
    },
    ownerId: {
      type: String,
      default: null,
    },
    accountId: {
      type: String,
      default: null,
    },
    customerSid: {
      type: String,
      default: null,
    },
    followers: {
      type: Array,
      default: () => [],
    },
    startTime: {
      type: Object,
      default: null,
    },
    endTime: {
      type: Object,
      default: null,
    },
    activityId: {
      type: Number,
      default: null,
    },
    resourceTypeId: {
      type: Number,
      default: null,
    },
    inventory: {
      type: Array,
      default: () => [],
    },
    crew: {
      type: Array,
      default: () => [],
    },
    unitId: {
      type: Number,
      default: null,
    },
    serviceProviderBusinessUnitId: {
      type: String,
      default: null,
    },
    costCenter: {
      type: String,
      default: () => '',
    },
    details: {
      type: String,
      default: () => '',
    },
    destinationLocationId: {
      type: Number,
      default: null,
    },
    attachmentsStorageId: {
      type: String,
      default: null,
    },
    customData: {
      type: Object,
      default: () => {},
    },
    tags: {
      type: Array,
      default: () => [],
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    divisionArray: {
      type: Array,
      default: () => [],
    },
    userArray: {
      type: Array,
      default: () => [],
    },
    isFetching: Boolean,
    customFieldsSchema: {
      type: Object,
      default: () => {},
    },
    errorFetchingExtensionSchema: {
      type: Object,
      default: null,
    },
    extensionSchemaId: {
      type: Number,
      default: null,
    },
    extensionSchema: {
      type: Object,
      default: () => {},
    },
    hasNewerCustomFieldsSchema: {
      type: Boolean,
      default: false,
    },
    timeSlotDates: {
      type: Array[String],
      default: [],
    },
    timeSlotStart: {
      type: String,
      default: '',
    },
    timeSlotDuration: {
      type: Number,
      default: 0,
    },
    bulkCreate: {
      type: Boolean,
      default: false,
    },
    forms: {
      type: Array,
      default: () => [],
    },
    formSubmissions: {
      type: Array,
      default: () => [],
    },
    approvalStatus: {
      type: String,
      default: () => '',
    },
    jobStatus: {
      type: String,
      default: () => '',
    },
    hubId: {
      type: String,
      required: true,
    },
    isReadonly: {
      type: Boolean,
    },
    isCancelled: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
    },
    canModifyRequester: {
      type: Boolean,
      required: true,
    },
    canScheduleJobsInPast: {
      type: Boolean,
      required: true,
    },
    canModifyPriority: {
      type: Boolean,
      required: true,
    },
    loadHistoryError: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Array,
      default: () => [],
    },
    comments: {
      type: Array,
      default: () => [],
    },
    assignmentGroupNumber: {
      type: Number,
      default: null,
    },
    poolId: {
      type: String,
      default: null,
    },
    assignmentFormRequirements: {
      type: Array,
      default: () => [],
    },
    batchId: {
      type: String,
      default: () => '',
    },
    mapLink: {
      type: String,
      default: null,
    },
    signature: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const query = useJobsSearch(
      {
        assignmentGroupNumber: props.assignmentGroupNumber,
        sortBy: ['number'],
        sortDesc: [true],
        offset: 0,
        limit: 10,
      },
      { enabled: !!props.assignmentGroupNumber }
    )

    const itineraries = computed(() => {
      return [
        ...(query.jobs.value
          ?.filter((j) => j.number !== props.jobId)
          .map((j) => ({
            job_id: j.id,
            startTime: moment(j.requested_start),
            endTime: moment(j.requested_end),
            extensionData: j.extension_data,
            stops: [
              ...j.inventory_requirements.map((i) => ({
                locationId: i.pickup_location_id,
                order: i.position,
                items: [],
              })),
              {
                locationId: j.destination_id,
                items: [],
                order: j.inventory_requirements.length,
              },
            ],
          })) || []),
        {
          job_id: props.jobUuid,
          startTime: props.startTime,
          endTime: props.endTime,
          extensionData: props.customData,
          stops: [
            ...props.inventory.map((i) => ({
              locationId: i.pickupLocationId,
              order: i.position,
              items: [],
            })),
            {
              locationId: props.destinationLocationId,
              items: [],
              order: props.inventory.length,
            },
          ],
        },
      ]
    })

    return {
      itineraries,
    }
  },
  data() {
    return {
      adHocPool: {
        stages: [{ stageNumber: 1, recipients: [] }],
        delay: null,
      },
      errorSchema: {},
      isCreatingAdHocPool: false,
      poolDefinitionId: null,
    }
  },

  computed: {
    ...mapGetters({
      getActivityById: 'activities/getById',
      getDivisionByUuid: 'divisions/getByUuid',
      getTaskById: 'tasks/getById',
      getFormSubmissionById: 'formSubmissions/getById',
      hasAnyVisibleForms: 'forms/getHasAnyVisibleForms',
      getUserBySid: 'users/getBySid',
      getBusinessUnitById: 'businessUnits/getBusinessUnitById',
      lineItemsFeatureFlag: 'featureFlags/lineItems',
      customFieldVersioningEnabled: 'featureFlags/customFieldVersioningEnabled',
    }),
    addFormsEnabled() {
      return this.hasAnyVisibleForms && !this.isNew
    },
    loadingExtensionSchema() {
      return !!(
        this.extensionSchemaId &&
        Object.keys(this.extensionSchema).length === 0 &&
        !this.isNew
      )
    },
    rootSchema() {
      let activitySchema = {}

      const hasExtensionSchema =
        this.extensionSchemaId &&
        Object.keys(this.extensionSchema).length !== 0 &&
        this.customFieldVersioningEnabled

      if (hasExtensionSchema) {
        activitySchema = {
          schema: this.extensionSchema?.data?.schema ?? {},
          uiSchema: this.extensionSchema?.data?.uiSchema ?? {},
        }
      } else {
        activitySchema = this.selectedTask?.jobCustomFieldsSchema ?? {}
      }

      const division = this.getDivisionByUuid(this.ownerId)
      const divisionSchema =
        division != null && division.jobCustomFieldsSchema
          ? division.jobCustomFieldsSchema
          : {}
      return mergeJsonSchemas(
        activitySchema,
        divisionSchema,
        this.customFieldsSchema
      )
    },
    schema() {
      return this.rootSchema.schema
    },
    uiSchema() {
      return this.rootSchema.uiSchema
    },
    defaultCustomData() {
      return this.contextualizedFormResolver.getDefaultFormData(
        this.schema,
        this.rootSchema
      )
    },
    shouldShowUpdateCustomFieldsBanner() {
      return (
        !this.isDisabled &&
        this.hasNewerCustomFieldsSchema &&
        this.customFieldVersioningEnabled
      )
    },
    selectedTask() {
      const activity = this.getActivityById(this.activityId)
      if (!activity) {
        return null
      }
      return this.getTaskById(activity.taskId)
    },
    activity() {
      return this.getActivityById(this.activityId)
    },
    canSelectPast() {
      return this.canScheduleJobsInPast || !!this.jobId
    },
    showTimeWarning() {
      return this.endTime.isBefore(this.startTime)
    },
    areAttachmentsEnabled() {
      return !(this.bulkCreate && this.timeSlotDates.length > 1)
    },
    canEditAttachments() {
      if (!this.areAttachmentsEnabled) {
        return false
      }

      return !this.isDisabled
    },
    canEditLineItems() {
      if (this.serviceProviderBusinessUnitId === null) return false
      const userBusinessUnitId = this.$auth.organizationId

      return this.serviceProviderBusinessUnitId !== userBusinessUnitId
    },
    showBulkCreateIcon() {
      return !this.jobId
    },
    cannotCloseBulkCreate() {
      return this.bulkCreate && this.timeSlotDates.length > 0
    },
    cannotOpenBulkCreate() {
      return !this.bulkCreate && this.attachments.length > 0
    },
    pickupLocationIds() {
      return this.inventory.map((i) => i.pickupLocationId)
    },

    contextualizedFormResolver() {
      const hub = this.getBusinessUnitById(this.hubId)
      const currentUser =
        this.getUserBySid(this.$auth.user.sub) || this.$auth.user

      const context = new FormContext({
        hub,
        currentUser,
        additionalContext: this.additionalContext,
      })
      return new ContextualizedFormResolver(context)
    },
    isDisabled() {
      return this.isReadonly || this.isCancelled
    },
    canViewLineItems() {
      if (!this.lineItemsFeatureFlag) return false
      if (this.isCancelled || this.isNew) return false
      if (this.serviceProviderBusinessUnitId === null) return false

      const hub = this.getBusinessUnitById(this.hubId)
      const isAssignedInternally =
        !!hub?.ownerId && this.serviceProviderBusinessUnitId === hub.ownerId

      return !isAssignedInternally
    },
  },
  watch: {
    schema: function (newSchema) {
      if (newSchema.properties) {
        this.$emit(
          'update:customData',
          mergeFormDataWithDefault(this.customData, this.defaultCustomData)
        )
      }
    },
  },
  created() {
    if (this.divisionArray.length === 1) {
      this.autoFill('division', this.divisionArray[0].uuid)
    }
    if (this.schema.properties) {
      this.$emit(
        'update:customData',
        mergeFormDataWithDefault(this.customData, this.defaultCustomData)
      )
    }
  },
  methods: {
    validate(jobStatus) {
      return (
        this.validateCustomFields() &&
        this.$refs.form.isValid() &&
        this.$refs['task-item'].validate(jobStatus)
      )
    },
    validateCustomFields() {
      const resolvedSchema = this.contextualizedFormResolver.retrieveSchema(
        this.schema,
        this.schema,
        this.customData
      )
      const resolvedData = resolveFormDataForValidation(this.customData)

      const errorSchema = validateFormData(resolvedSchema, resolvedData)
      this.errorSchema = errorSchema

      return isEmpty(errorSchema)
    },
    /**
     * `update:extension-schema-id` is insufficient here because we want
     * Ticket.vue to show snackbar warning.
     */
    updateSchemaButtonClicked() {
      this.$emit('update-schema-button-clicked')
    },
    updateStartTime(newValue) {
      this.$emit('update-start-time', newValue)
    },
    removeJobFromGroup() {
      this.$emit('remove-job-from-group')
    },
    updateEndTime(value) {
      this.$emit('update:endTime', value)
    },
    updateDestination(id) {
      this.$emit('update:destinationLocationId', id)
    },
    updateCostCenter(value) {
      this.$emit('update:costCenter', value)
    },
    updatePriority(id) {
      this.$emit('update:priorityId', id)
    },
    updateActivity(value) {
      const activity = this.getActivityById(value)

      if (activity) {
        const task = this.getTaskById(activity.taskId)
        this.$emit('update:extension-schema-id', task.version)
      }

      this.$emit('update:activityId', value)
    },
    updateCrew(crew) {
      this.$emit('update:crew', crew)
    },
    updateInventory(inventory) {
      this.$emit('update:inventory', inventory)
    },
    updateUnit(unitId) {
      this.$emit('update:unitId', unitId)
    },
    updateServiceProviderBusinessUnitId(serviceProviderBusinessUnitId) {
      if (
        serviceProviderBusinessUnitId !== null &&
        serviceProviderBusinessUnitId !== this.serviceProviderBusinessUnitId
      ) {
        this.updateAssignmentFormRequirements([])
      }
      this.$emit(
        'update:serviceProviderBusinessUnitId',
        serviceProviderBusinessUnitId
      )
    },
    updateDetails(value) {
      this.$emit('update:details', value)
    },
    updateTags(value) {
      this.$emit('update:tags', value)
    },
    updateCustomData(value) {
      this.$emit('update:customData', value)
    },
    updateAssignmentFormRequirements(requirements) {
      this.$emit('update:assignment-form-requirements', requirements)
    },
    fillFormRequirement(value) {
      this.$emit('fill-form-requirement', value)
    },
    viewFormRequirementSubmission(value) {
      this.$emit('view-form-requirement-submission', value)
    },
    showAssignmentFormSelection() {
      this.$emit('show-assignment-form-selection')
    },
    showLineItemBottomSheet() {
      this.$emit('show-line-item-bottom-sheet')
    },
    showSignatureBottomSheet() {
      this.$emit('show-signature-bottom-sheet')
    },
    showNewGuestUserBottomSheet() {
      this.$emit('show-new-guest-user-bottom-sheet')
    },
    handleAttachmentAdded(value) {
      this.$emit('update:attachments', [...this.attachments, value])
    },
    handleAttachmentUpdated(value) {
      this.$emit('update:attachments', [
        ...this.attachments.filter((a) => a.id !== value.id),
        value,
      ])
    },
    handleAttachmentRemoved(value) {
      this.$emit(
        'update:attachments',
        this.attachments.filter((a) => a.id !== value)
      )
    },
    unsupportedFileSelected(messageKey) {
      this.$emit('unsupported-file-selected', messageKey)
    },
    errorUploadingAttachment(messageKey) {
      this.$emit('error-uploading-attachment', messageKey)
    },
    toggleBulkCreate() {
      this.$emit('update:bulkCreate', !this.bulkCreate)
    },
    updateTimeSlotDates(value) {
      this.$emit('update:timeSlotDates', value)
    },
    updateTimeSlotStart(value) {
      this.$emit('update:timeSlotStart', value)
    },
    updateTimeSlotDuration(value) {
      this.$emit('update:timeSlotDuration', value)
    },
    editFormSubmission(formId, formSubmissionId) {
      this.$emit('edit-form-submission', formId, formSubmissionId)
    },
    fillFormSubmission(formId) {
      this.$emit('fill-form-submission', formId)
    },
    showFormSelection() {
      this.$emit('show-form-selection')
    },
    retryFetchingExtensionSchema() {
      this.$emit('retry-custom-schema-fetch')
    },
    getAdhocFormSubmissionSecondaryText(formSubmission) {
      const userName = this.getUserBySid(formSubmission.submittedBy)?.name
      const uploadedTime = formatDateTime(formSubmission.submittedOn)

      return userName ? `${userName} on ${uploadedTime}` : `${uploadedTime}`
    },
    createComment(comment) {
      this.$emit('create-comment', comment)
    },
    clearSignature() {
      this.$emit('clear-signature')
    },
    resetAdHocPool() {
      this.isCreatingAdHocPool = false
      this.adHocPool = {
        stages: [{ stageNumber: 1, recipients: [] }],
        delay: null,
      }
    },
    autoFill(field, value) {
      this.$emit('auto-fill', field, value)
    },
    handleDiscard() {
      this.poolDefinitionId = null
      this.isCreatingAdHocPool = false
    },
    onPoolClosed() {
      this.$emit('pool:closed')
    },
    assignToPool(id) {
      this.poolDefinitionId = id
      this.$emit('clear-assigned-pool')
      if (id == null) {
        this.updatePoolExecutionPlan(null)
      } else {
        this.updateAssignmentFormRequirements([])
      }
    },
    updatePoolExecutionPlan(value) {
      this.$emit('update-pool-execution-plan', value)
    },
    createAdHocPool(value) {
      if (value === true) this.updateAssignmentFormRequirements([])
      this.isCreatingAdHocPool = value
      this.adHocPool = {
        stages: [{ stageNumber: 1, recipients: [] }],
        delay: null,
      }
    },
    updateAdHocPoolDelay(value) {
      this.adHocPool.delay = value
      this.updatePoolExecutionPlanWithAdHocPool()
    },
    addAdHocPoolStage(value) {
      this.adHocPool.stages.push({
        stageNumber: value,
        recipients: [],
      })
      this.updatePoolExecutionPlanWithAdHocPool()
    },
    updateAdHocPoolStage(value) {
      this.adHocPool.stages.splice(value.stageNumber - 1, 1, value)
      this.updatePoolExecutionPlanWithAdHocPool()
    },
    removeAdHocPoolStage(stageNumber) {
      if (this.adHocPool.stages.length === 1) {
        this.isCreatingAdHocPool = false
        return
      }

      this.adHocPool.stages.splice(stageNumber - 1, 1)

      this.adHocPool.stages.forEach((s) => {
        if (s.stageNumber > stageNumber) {
          s.stageNumber = s.stageNumber - 1
        }
      })
      this.updatePoolExecutionPlanWithAdHocPool()
    },
    updatePoolExecutionPlanWithAdHocPool() {
      let delay = null

      if (this.adHocPool.delay) {
        const minutes = parseInt(this.adHocPool.delay)
        const days = Math.floor(minutes / (60 * 24))
        const hours = Math.floor((minutes % (60 * 24)) / 60)
        const remainingMinutes = minutes % 60

        delay = `${days}:${hours}:${remainingMinutes}:00`
      }

      const stages = this.adHocPool.stages.map((s) => ({
        delay,
        stage_number: s.stageNumber,
        recipients: s.recipients.map((r) => ({
          recipient_id: r,
        })),
      }))
      const executionPlan = { stages }
      this.updatePoolExecutionPlan(executionPlan)
    },
  },
}
</script>

<style scoped>
.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--space-smaller) * -1) calc(var(--space-small) * -1);
}

.grid-item {
  flex: 1;
  min-width: 50%;
  padding: 0 var(--space-smaller) var(--space-small);
}

.add-text {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  color: var(--color-grey--light);
}

.progress-center {
  height: 100%;
  width: 100%;
  display: flex;
}
</style>
