import { render, staticRenderFns } from "./SendForApprovalBottomSheet.vue?vue&type=template&id=ca4d2816&scoped=true&"
import script from "./SendForApprovalBottomSheet.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SendForApprovalBottomSheet.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SendForApprovalBottomSheet.vue?vue&type=style&index=0&id=ca4d2816&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca4d2816",
  null
  
)

export default component.exports